import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./introductions.scss"

const Introductions = () => {
  return (
    <article className="introductions article">
      <h1>Blogin kirjoittajat esittäytyvät</h1>
      <div className="person">
        <StaticImage
          src="../images/jori2.png"
          alt="Jori"
          placeholder="blurred"
          objectFit="contain"
          objectPosition="50% 50%"
          width={"90%"}
        />
        <div className="personInfo">
          <h2>Jori Kuttila</h2>
          <p>
            Minulla on vahva kokemus liiketoiminnan, B2B myynnin ja digitaalisen
            markkinoinnin tuloksellisesta johtamisesta sekä niiden
            kehittämisestä.
          </p>
          <p>
            Missionani on vuorovaikutteisen ja valmentavan johtamisen
            kehittäminen.
          </p>
          <p>
            Oman ajatteluni ja toimintani ydin voidaan kiteyttää ”pehmein
            keinoin koviin tuloksiin”.
          </p>
          <a href="mailto:jori.kuttila@salesimpact.fi" target="_blank">
            jori.kuttila@salesimpact.fi
          </a>
          <br />
          <a href="https://www.salesimpact.fi" target="_blank">
            www.salesimpact.fi
          </a>
          <br />
          <a href="tel:+35840022943">Puh. 0400 229 436</a>
        </div>
      </div>
      <div className="person">
        <StaticImage
          src="../images/markku2.png"
          alt="Markku"
          placeholder="blurred"
          objectFit="contain"
          objectPosition="50% 50%"
          width={"90%"}
        />
        <div className="personInfo">
          <h2>Markku Hugg</h2>
          <p>Olen B2B myynnin ja digitaalisen markkinoinnin moniosaaja.</p>
          <p>
            Uskon, että voittava konsepti koostuu ihmisten osallistamisesta,
            systematiikasta, jota tuetaan digitaalisilla alustoilla ja
            positiivisesta asiakaskokemuksesta.
          </p>
          <p>Tavoitteenani on tehdä parempaa suomalaista työelämää.</p>
          <a href="mailto:markku.hugg@gmail.com" target="_blank">
            markku.hugg@gmail.com
          </a>
          <br />
          <a href="tel:+358400258410">Puh. 0400 258 410</a>
        </div>
      </div>
    </article>
  )
}

export default Introductions
