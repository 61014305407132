import React from "react"
import "./articleCard.scss"

const ArticleCard = props => {
  return (
    <>
      <div className="articleCard">
        <img src={props?.image?.fixed?.src} alt="" />
        <div className="text">
          <h3 id={props.slug}>{props.title}</h3>

          {props.index == 0 && props.summary && (
            <p className="summary">{props.summary}</p>
          )}
          <p className="date">
            {/* Julkaistu{" "}
            <time dateTime={props.date}>
              {new Date(props.date).toLocaleDateString("fi-FI")}
            </time> */}
          </p>
          <a href={`/${props.slug}`}>Lue lisää</a>
        </div>
      </div>
    </>
  )
}

export default ArticleCard
