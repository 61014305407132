import { graphql } from "gatsby"
import React from "react"
import ArticleCard from "../components/articleCard"
import ContentBlock from "../components/contentBlock"
import Header from "../components/header"
import Introductions from "../components/introductions"
import SEO from "../components/SEO"
import "./index.scss"

export default function IndexPage({ data }) {
  const { indexPage, articles } = data
  return (
    <>
      <SEO seo={indexPage.seoMetaTags} />

      <Header />
      <main className="frontpage">
        <div className="articles">
          {articles.edges.map(({ node }, i) => {
            return <ArticleCard key={i} index={i} {...node} />
          })}
        </div>
        {data.indexPage.contentBlock && (
          <div className="article">
            <ContentBlock content={data.indexPage.contentBlock} />
          </div>
        )}
        <Introductions />
      </main>
    </>
  )
}

export const indexPageQuery = graphql`
  query frontpageQuery {
    indexPage: datoCmsEtusivu {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      contentBlock {
        title
        productTitle
        productDescription
        buttonUrl
        buttonTitle
        iconSections {
          description
          title
          icon {
            url
          }
        }
      }
    }

    articles: allDatoCmsArticle(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          ...DatoArticle
        }
      }
    }
  }
`
