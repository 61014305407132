import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Header = () => {
  return (
    <header>
      <div className="heroTop">
        {" "}
        <h1>VIIVAN ALLE MUSTAA</h1>
        <h2>
          Johda ihmistä, johdat tulosta. <br /> <b>Ja nautit elämästä.</b>
        </h2>
      </div>
      <div className="heroBottom">
        <div className="person">
          <StaticImage
            src="../images/markku.png"
            alt="Markku"
            placeholder="blurred"
            objectFit="contain"
            objectPosition="100% 100%"
          />
          <div className="personInfo">
            <h3>Markku</h3>
            <div className="quote">
              <span>’’</span>
              <i>Tavoitteenani on tehdä parempaa suomalaista työelämää.</i>
            </div>
          </div>
        </div>
        <div className="person">
          <StaticImage
            src="../images/jori.png"
            alt="Jori"
            placeholder="blurred"
            objectFit="contain"
            objectPosition="0% 100%"
          />
          <div className="personInfo">
            <h3>Jori</h3>
            <div className="quote">
              <i>
                <span>’’</span>Pehmein keinoin koviin tuloksiin
              </i>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
