import React from "react"
import "./contentBlock.scss"

const ContentBlock = props => {
  const {
    title,
    productTitle,
    productDescription,
    buttonTitle,
    buttonUrl,
    iconSections,
  } = props.content
  return (
    <div className="contentBlock">
      {title && <h2>{title}</h2>}
      <div className="columns">
        <div className="first">
          {iconSections?.map((elem, key) => {
            return (
              <div className="iconTextBlock" key={key}>
                {elem.icon && <img src={elem.icon?.url} alt={elem.title} />}
                <div>
                  {elem.title && <h3>{elem?.title}</h3>}
                  {elem.description && <p>{elem?.description}</p>}
                </div>
              </div>
            )
          })}
        </div>
        <div className="last">
          <div>
            {productTitle && <h3>{productTitle}</h3>}
            {productDescription && <p> {productDescription}</p>}
            {buttonUrl && buttonTitle && (
              <a target="_blank" href={buttonUrl}>
                {buttonTitle}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContentBlock
